<template>
    <modal ref="modalAgregarContactoCliente" titulo="Añadir contacto de cliente" adicional="Añadir" @adicional="agregarContactoCliente">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-9 my-2">
                <p class="input-label-top">Contacto</p>
                <el-select v-model="idCliente" placeholder="Seleccionar contacto" size="small" class="w-100">
                    <el-option v-for="item in selectClientes" :disabled="item.disable" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data(){
        return {
            idCliente: '',
        }
    },
    async created() {
        await this.Action_get_select_opportunity_customers(this.$route.params.id)
    },
    computed: {
        ...mapGetters({
            clientes: 'selects/selects/select_opportunity_customers',
            oportunidadesClientes: 'oportunidades/oportunidades/oportunidadesClientes',
        }),
        selectClientes(){
            if(Object.values(this.clientes).length > 0){
                this.clientes.map(e => {
                    e.disable = this.oportunidadesClientes.some(s => s.id === e.id)
                })
                return this.clientes
            }
        }
    },
    methods: {
        ...mapActions({
            Action_get_select_opportunity_customers: 'selects/selects/Action_get_select_opportunity_customers',
            Action_add_opportunity_customer: 'oportunidades/oportunidades/Action_add_opportunity_customer',
        }),
        toggle(){
            this.idCliente = ''
            this.$refs.modalAgregarContactoCliente.toggle()
        },
        async agregarContactoCliente(){
            await this.Action_add_opportunity_customer({id: this.$route.params.id, payload: {id_responsable: this.idCliente}})
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
