<template>
    <div v-if="ciclo" class="col-auto py-2 mb-auto ciclos w150">
        <div class="row mx-0 justify-content-center">

            <div v-if="ciclo.estado === 1" class="col-auto text-center">
                <el-tooltip placement="bottom" effect="light" visible-arrow>
                    <template #content>
                        <button class="btn btn-cerrar f-12 mx-1" @click="editarCiclo">
                            Editar
                        </button>
                        <button class="btn btn-general btn-crear f-12 mx-1" @click="avanzarCiclo">
                            Avanzar
                        </button>
                    </template>
                    <div class="wh-50 rounded-circle item border d-middle-center mx-auto mb-1 cr-pointer">
                        <i class="f-25 text-gris" :class="ciclo.icon" />
                    </div> 
                </el-tooltip>
                <p class="f-12 w-100 mb-1"> {{ ciclo.ciclo }} </p>
                <p v-if="ciclo.estado !== 0" class="f-12 w-100 font-italic mb-2"> {{ ciclo.fecha | helper-fecha('DD/MM/YYYY') }} </p>
                <span v-if="ciclo.estado !== 0" :class="colorDias" class="text-white mx-auto f-12 text-white rounded-pill">
                    {{ ciclo.dias }}
                </span>
            </div>

            <div v-else class="col-auto text-center">
                <div :class="{'border' : cicloFaltante, 'bg-general' : !cicloFaltante}" class="wh-50 rounded-circle item d-middle-center mx-auto mb-1 cr-pointer">
                    <i :class="[{'text-gris' : cicloFaltante, 'text-white' : !cicloFaltante}, ciclo.icon]" class="f-25" @click="verCicloFinalizado" />
                </div> 
                <p class="f-12 w-100 mb-1"> {{ ciclo.ciclo }} </p>
                <p v-if="ciclo.estado !== 0" class="f-12 w-100 font-italic mb-2"> {{ ciclo.fecha | helper-fecha('DD/MM/YYYY') }} </p>
                <span v-if="ciclo.estado !== 0" :class="colorDias" class="mx-auto text-white f-12 text-white rounded-pill">
                    {{ ciclo.dias }}
                </span>
            </div>
        </div>
        <modal-editar-ciclo ref="modalEditarCiclo" />
    </div>
</template>

<script>
import ModalEditarCiclo from '../partials/modalEditarCiclo.vue'
export default {
    props: {
        ciclo: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        ModalEditarCiclo,
    },
    computed: {
        cicloFaltante(){
            if (this.ciclo.estado === 0 ){
                return true
            }else{
                return false
            }
        },
        colorDias(){
            switch (this.ciclo.color) {
                case 'green':
                    return 'antes-fecha'
                case 'red':
                    return 'despues-fecha'
                case 'gray':
                    return 'para-fecha'
                default:
                    break;
            }
        },
    },
    methods: {
        verCicloFinalizado(){
            if(this.ciclo.estado === 2) this.$router.push({ name: 'oportunidades.ciclos.finalizados', params: {nom_ciclo: this.ciclo.ciclo}})
        },
        editarCiclo(){
            this.$refs.modalEditarCiclo.toggle(this.ciclo)
        },
        avanzarCiclo(){
            this.$router.push({ name: 'oportunidades.ciclos.avanzar', params: {id_ciclo_ops: this.ciclo.id_ops}})
        },
    },
}
</script>

<style lang="scss" scoped>
    .ciclos{
        .antes-fecha{
            background-color:#3CD273;
        }
        .despues-fecha{
            background-color:#FF2449;
        }
        .para-fecha{
            background-color:#5D5D5D;
        }
        &::after{
            content: '';
            width: 57px;
            height: 1px;
            background: #DBDBDB;
            position: absolute;
            top: 32px;
            right: -28px;
        }
        &:last-child{
            &::after{
                display: none !important;
            }
        }
        .rounded-pill{
            padding: 2px 10px;
        }
    }
</style>