<template>
    <modal ref="modalEditarCiclo" titulo="Editar ciclo" adicional="Guardar" @adicional="editarCiclo">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha actual de cierre</p>
                    <el-date-picker v-model="ciclo.fecha" format="dd/MM/yyyy" type="date" size="small" class="w-100" placeholder="Fecha actual" disabled />
                </div>
                <div class="col-6 my-3">
                    <p class="input-label-top">Nueva Fecha de cierre</p>
                    <ValidationProvider v-slot="{ errors }" name="fecha" rules="required">
                        <el-date-picker v-model="nuevafechaCierre" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" size="small" class="w-100" placeholder="Nueva fecha"  />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Nueva estrategia</p>
                    <ValidationProvider v-slot="{ errors }" name="estrategia" rules="required">
                        <el-input v-model="inputNuevaEstrategia" type="textarea" placeholder="Nueva estrategia" :autosize="{ minRows: 3}" size="small" class="w-100" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            nuevafechaCierre: '',
            inputNuevaEstrategia: '',
            ciclo: '',
        }
    },
    methods: {
        ...mapActions({
            Action_edit_opportunity_ciclos: 'oportunidades/oportunidades/Action_edit_opportunity_ciclos',
            Action_get_opportunity_ciclos: 'oportunidades/oportunidades/Action_get_opportunity_ciclos',
        }),
        toggle(ciclo){
            this.ciclo = ciclo
            this.inputNuevaEstrategia = ''
            this.nuevafechaCierre = null
            this.$refs.validator.reset()
            this.$refs.modalEditarCiclo.toggle()
        },
        async editarCiclo(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                const params = {
                    idOportunidad: this.$route.params.id,
                    idCiclo: this.ciclo.id,
                    payload: {
                        nueva_fecha_cierre: this.nuevafechaCierre,
                        nueva_estrategia: this.inputNuevaEstrategia
                    }
                }
                await this.Action_edit_opportunity_ciclos(params)
                this.$refs.modalEditarCiclo.toggle()
                await this.Action_get_opportunity_ciclos(this.$route.params.id)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
