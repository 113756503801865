<template>
    <section class="section-ciclos">
        <titulo-divisor v-if="title" titulo="Ciclos de oportunidad" class="mr-0" />
        <div class="d-flex listado-ciclos overflow-auto custom-scroll" style="max-width:100%">
            <ciclo v-for="(item, index) in data" :key="index" :ciclo="item" />
        </div>
    </section>
</template>

<script>
import Ciclo from './ciclo.vue'
export default {
    components: {
        Ciclo,
    },
    props: {
        title: {
            type: Boolean,
            default: true
        },
        dataCiclo: {
            type: Array,
            default: () => []
        },
        icon: {
            type: Array,
            default: () => [
                'icon-bullseye-arrow',
                'icon-map-marker',
                'icon-chart-bar',
                'icon-handshake',
                'icon-file-document-edit-outline',
                'icon-cash-multiple',
            ]
        },
    },
    data() {
        return {
            fullData: new Array
        }
    },
    computed: {
        data(){
            if (this.dataCiclo.length && this.dataCiclo.length <= this.icon.length) {
                this. fullData = this.dataCiclo
                this.fullData.map((e, index) => e.icon = this.icon[index])
                return this.fullData
            }else{
                return []
            }
        },
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
    .listado-ciclos{
        .w150{
            min-width: 150px;
            width: 150px;
            max-width: 150px;
        }
        .ciclos{
            .antes-fecha{
                background-color:#3CD273;
            }
            .despues-fecha{
                background-color:#FF2449;
            }
            &::after{
                content: '';
                width: 57px;
                height: 1px;
                background: #DBDBDB;
                position: absolute;
                top: 32px;
                right: -28px;
            }
            &:last-child{
                &::after{
                    display: none !important;
                }
            }
            .rounded-pill{
                padding: 2px 10px;
            }
        }
    }
</style>