<template>
    <modal ref="modalEditarOp" titulo="Editar oportunidad" tamano="modal-lg" adicional="Guardar" @adicional="editarOp">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-6 mb-2">
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <p class="input-label-top">Nombre de la oportunidad</p>
                        <el-input v-model="edit.nombre" size="small" class="w-100" placeholder="Nombre Oportunidad" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-6 mb-2">
                    <ValidationProvider v-slot="{ errors }" name="tipo de oportunidad" rules="required">
                        <p class="input-label-top">Tipo de oportunidad</p>
                        <el-select v-model="edit.id_oportunidad_tipo" placeholder="Seleccionar tipo" filterable size="small" class="w-100">
                            <el-option v-for="item in select_opportunity_type" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row justify-content-center m-3 f-15">
                <div class="col-4 mb-2">
                    <ValidationProvider v-slot="{ errors }" name="fecha de identifiación" rules="required">
                        <p class="input-label-top">Fecha de identificación</p>
                        <el-date-picker v-model="edit.fecha_identificacion" type="date" size="small" class="w-100" placeholder="Fecha identificación" disabled />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-4 mb-2">
                    <ValidationProvider v-slot="{ errors }" name="fecha de cierre" rules="required">
                        <p class="input-label-top">Fecha de cierre</p>
                        <el-date-picker v-model="edit.fecha_cierre"
                            type="date" size="small" class="w-100"
                            placeholder="Fecha cierra" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-4 mb-2">
                    <ValidationProvider v-slot="{ errors }" name="probabilidad" rules="required">
                        <p class="input-label-top">Probabilidad</p>
                        <el-input v-model="edit.probabilidad"  size="small" class="w-100" placeholder="Valor %"  />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-3">
                    <ValidationProvider v-slot="{ errors }" name="descripción" rules="required">
                        <p class="input-label-top">Descripción</p>
                        <el-input v-model="edit.descripcion" type="textarea" placeholder="Descripción" :autosize="{ minRows: 3}" size="small" class="w-100" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data(){
        return {
            edit: {
                nombre: '',
                id_oportunidad_tipo: '',
                fecha_identificacion: '2021-12-01',
                fecha_cierre: null,
                probabilidad: '',
                descripcion: '',
            },
        }
    },
    created () {
        this.Action_get_select_opportunity_type()
    },
    computed: {
        ...mapGetters({
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            select_opportunity_type: 'selects/selects/select_opportunity_type'
        }),
    },
    methods: {
        ...mapActions({
            Action_get_select_opportunity_type: 'selects/selects/Action_get_select_opportunity_type',
            Action_edit_opportunity_information: 'oportunidades/oportunidades/Action_edit_opportunity_information',
            Action_get_opportunity_information: 'oportunidades/oportunidades/Action_get_opportunity_information',
        }),
        llenarCampos(){
            this.edit.nombre = this.oportunidadesInformacion.nombre_oportunidad
            this.edit.fecha_identificacion = this.oportunidadesInformacion.identificacion
            this.edit.fecha_cierre = this.oportunidadesInformacion.cierre
            this.edit.probabilidad = this.oportunidadesInformacion.probabilidad
            this.edit.descripcion = this.oportunidadesInformacion.descripcion
            this.select_opportunity_type.forEach(e => {
               if (e.nombre == this.oportunidadesInformacion.tipo) {
                   this.edit.id_oportunidad_tipo = e.id
               } 
            });
        },
        toggle(){
            this.$refs.validator.reset()
            this.llenarCampos()
            this.$refs.modalEditarOp.toggle()
        },
        async editarOp(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                await this.Action_edit_opportunity_information({id: this.oportunidadesInformacion.id, payload: this.edit})
                await this.Action_get_opportunity_information(this.oportunidadesInformacion.id)
                this.toggle()
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
