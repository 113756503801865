<template>
    <section>
        <titulo-divisor titulo="Equipos" class="mr-0">
            <div class="row">
                <div class="col-auto my-auto px-1">
                    <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                        <template #content>
                            <p class="p-2 cr-pointer item" @click="agregarEquipoModelo">Añadir equipo modelo</p>
                            <p class="p-2 cr-pointer item" @click="agregarOportunidadEquipo">Cargar de otra oportunidad</p>
                        </template>
                        <button class="btn btn-general f-12 px-4">
                            <p class="d-middle">
                                Añadir equipo
                                <i class="icon-menu-down ml-2 f-14" />
                            </p>
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div class="my-2 d-flex overflow-auto custom-scroll gap-2">
            <div v-for="(data, index) in oportunidadesEquipos" :key="index" class="mb-3">
                <card-equipo
                    :name="data.nombre"
                    :image="data.imagen"
                    :price="data.precio"
                    :cantPhases="data.etapas"
                    :cantMaterials="data.materiales"
                    :cantHours="data.horas_hombre"
                />
            </div>
        </div>
        <modal-agregar-equipo-modelo ref="modalAgregarEquipoModelo" />
        <modal-agregar-equipo-op ref="modalAgregarEquipoOp" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    components: {
        modalAgregarEquipoModelo: () => import('../../../partials/modalAgregarEquipoModelo'),
        modalAgregarEquipoOp: () => import('../../../partials/modalAgregarEquipoOp'),
    },
    computed: {
        ...mapGetters({
            oportunidadesEquipos: 'oportunidades/oportunidades/oportunidadesEquipos'
        }),
    },
    async created () {
        await this.Action_get_opportunity_equipos(this.$route.params.id);
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_equipos: 'oportunidades/oportunidades/Action_get_opportunity_equipos'
        }),
        agregarEquipoModelo(){
            this.$refs.modalAgregarEquipoModelo.toggle()
        },
        agregarOportunidadEquipo(){
            this.$refs.modalAgregarEquipoOp.toggle()
        },
    },
}
</script>

<style lang="scss" scoped>

</style>