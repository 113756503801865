<template>
    <modal ref="modalEditarFormaPago" titulo="Forma de pago" tamano="modal-lg" adicional="Guardar" @adicional="validateFinal">
        <ValidationObserver ref="veeValidator" class="w-100 row mt-3 f-15">
            <div class="col-4 my-3">
                <ValidationProvider v-slot="{ errors }" name="forma de pago" rules="required">
                    <p class="input-label-top">Forma de pago</p>
                    <el-select v-model="tipoPago" placeholder="Seleccionar forma de pago" size="small" class="w-100">
                        <el-option v-for="item in formasPago" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                    <vee-error :text="errors[0]"></vee-error>
                </ValidationProvider>
            </div>
            <div class="col-4 my-3">
                <ValidationProvider v-slot="{ errors }" name="fecha de pago" rules="required">
                    <p class="input-label-top">Fecha de pago</p>
                    <el-date-picker v-model="payment.fecha_pago" type="date" value-format="yyyy-MM-dd" size="small" class="w-100" placeholder="Seleccionar fecha"  />
                    <vee-error :text="errors[0]"></vee-error>
                </ValidationProvider>
            </div>
            <div v-if="tipoPago === 2" class="col-4 my-3">
                <p class="input-label-top">Días de crédito</p>
                <div class="d-middle">
                    <i class="icon-calendar-today" />
                    <p class="f-14 f-500">{{ payment.credito_dias }}</p>
                </div>
            </div>
            <div class="col-12 my-3">
                <ValidationProvider v-slot="{ errors }" name="observaciones" rules="required">
                    <p class="input-label-top">Observaciones</p>
                    <el-input v-model="payment.justificacion" type="textarea" placeholder="Observaciones" :autosize="{ minRows: 3}" size="small" class="w-100" />
                <vee-error :text="errors[0]"></vee-error>
                </ValidationProvider>
            </div>
        </ValidationObserver>
        <section class="mx-3" v-if="tipoPago === 4">
             <div class="row f-14 my-4 justify-content-end">
                <div class="col-6 d-flex">
                    <p>Fijar cuota por:</p>
                    <div class="my-auto ml-2">
                        <el-radio v-model="radioButton" label="1" class="f-12">Porcentaje</el-radio>
                        <el-radio v-model="radioButton" label="2" class="f-12">Valor</el-radio>
                    </div>
                </div>
            </div>
            <div class="row mx-0 border mt-4 bg-general text-white py-2">
                <div class="col-2 my-auto">
                    <p class="f-600 f-14">Cuotas</p>
                </div>
                <div class="col-4 my-auto">
                    <p class="f-600 f-14">Fecha de pago</p>
                </div>
                <div class="col-2 my-auto">
                    <p class="f-600 f-14">Porcentaje</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-600 f-14">Valor</p>
                </div>
                <div class="col-1 my-auto d-middle-center position-relative">
                    <i class="icon-plus-box f-21 cr-pointer" @click="popover = !popover" />
                    <div v-if="popover" class="popover position-absolute d-flex flex-column shadow border">
                        <p class="f-14 f-600 text-general text-center my-2">Añadir cuotas</p>
                        <div class="hr" />
                        <div class="px-4 mt-3">
                            <p class="input-label-top text-muted">Número de cuotas</p>
                            <input 
                                v-model="numCuotas"
                                type="number"
                                @focus="$event.target.select()"
                                @keydown.enter="asignarNumeroCuotas"
                                class="w-100 px-0 mb-2 px-2 border mr-1"
                            />
                            <button @click="asignarNumeroCuotas" class="w-100 bg-general border-0 br-5 f-12 text-white py-2">Añadir</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-scroll px-3 border shadow-inner" style="max-height: 230px; overflow-x: hidden;">
                <div v-for="(cuota,i) in cuotas" :key="i" class="row border-bottom py-2">
                    <div class="col-2 my-auto">
                        <p class="f-12">Cuota {{ cuota.id = i+1 }}</p>
                    </div>
                    <div class="col-4 my-auto position-relative">
                        <el-date-picker v-model="cuota.fecha"
                            :picker-options="pickerOptions"
                            type="date"
                            size="small"
                            :class="{'validate-fecha': validateFecha(i), 'validate': validateCampos(i, 1)}"
                            class="w-100 br-5"
                            placeholder="Seleccionar un día"
                        />
                        <el-tooltip v-if="validateCampos(i, 1)" content="Este campo no puede quedar vacio" placement="top">
                            <i class="icon-alert text-danger icon-alert f-12 icon-validate" style="right: 13px;" />
                        </el-tooltip>
                        <el-tooltip v-else-if="validateFecha(i)" content="La fecha no debe ser menor a la anterior" placement="top">
                            <i class="icon-alert text-warning icon-alert text- f-12 icon-validate" style="right: 13px;" />
                        </el-tooltip>
                    </div>
                    <div class="col-2 my-auto d-middle-center position-relative">
                        <input 
                            v-model="cuota.porcentaje"
                            type="number"
                            @focus="$event.target.select()"
                            @input="valorPorPorcentaje(i)"
                            :disabled="radioButton != 1"
                            :class="validateCampos(i, 2) && radioButton == 1 ? 'validate' : 'border'"
                            class="w-80 f-13 text-muted py-1 px-2 br-5"
                        />
                        <el-tooltip v-if="validateCampos(i, 2) && radioButton == 1" content="Este campo no puede quedar vacio" placement="top">
                            <i class="icon-alert text-danger icon-alert f-12 icon-validate" style="right: 38px;" />
                        </el-tooltip>
                        <p class="ml-2 text-muted">%</p>
                    </div>
                    <div class="col-3 my-auto position-relative">
                        <CustomCurrencyInput 
                            :options="{ currency: 'USD'}"
                            :disabled="radioButton != 2"
                            v-model="cuota.valor" 
                            @input="porcentajePorValor(i)"
                            :class="validateCampos(i, 2) && radioButton == 2 ? 'validate' : 'border'"
                            class="w-100 text-muted f-14 br-5"
                        />
                        <el-tooltip v-if="validateCampos(i, 3) && radioButton == 2" content="Este campo no puede quedar vacio" placement="top">
                            <i class="icon-alert text-danger icon-alert f-12 icon-validate" style="right: 16px;" />
                        </el-tooltip>
                    </div>
                    <div class="col-1 my-auto text-center icon-option">
                        <i class="icon-close-circle cr-pointer f-20 text-ddd" @click="deleteCuotaId(cuota.id)" />
                    </div>
                </div>
            </div>
            <div class="row mt-2 mx-0 py-2">
                <div class="col-2 my-auto" />
                <div class="col-4 my-auto text-right">
                    <span class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">Total</span>
                </div>
                <div class="col-2 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">{{totalPorcentaje}}%</div>
                </div>
                <div class="col-3 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">{{  totalValor | currency('$', 1, { thousandsSeparator: '.' }) }}</div>
                </div>
                <div class="col-auto my-auto" />
            </div>
        </section>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {Notification} from 'element-ui'
import moment from 'moment';
export default {
    data(){
        return {
            formasPago: [
                {
                    value: 1,
                    label: 'Contado'
                }, 
                {
                    value: 2,
                    label: 'Credito'
                }, 
                {
                    value: 3,
                    label: 'Contraentrega'
                }, 
                {
                    value: 4,
                    label: 'Cuotas'
                }
            ],
            tipoPago: '',
            inputObservaciones: '',
            radioButton: '1',
            popover: false,
            cuotas: [],
            numCuotas: '',
            payment: {
                credito: 0,
                cuotas: [],
                fecha_pago: null,
                forma_pago: "",
                id: null,
                justificacion: "",
                valor: "",

            },
            cuotaDefault: {
                id: null,
                fecha: '',
                porcentaje: '',
                valor: 0,
            },
        }
    },
    watch: {
        numCuotas(val) {
            if (val.length > 2)
            this.numCuotas = this.numCuotas.slice(0,2);
        }
    },
    computed: {
        ...mapGetters({
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            paymentMethod: 'oportunidades/oportunidades/paymentMethod'
        }),
        totalPorcentaje(){
            let total = 0
            this.cuotas.forEach(el => total += Number(el.porcentaje))
            return total
        },
        totalValor(){
            let total = 0
            this.cuotas.forEach(el => total += Number(el.valor))
            return total
        },
        pickerOptions(){
            return {
                disabledDate(time){
                    return time.getTime() < Date.now()
                }
            }
        },
    },
    methods: {
        ...mapActions({
            Action_set_opportunity_payment_method: 'oportunidades/oportunidades/Action_set_opportunity_payment_method',
            Action_get_opportunity_payment_method: 'oportunidades/oportunidades/Action_get_opportunity_payment_method'
        }),
        toggle(){
            this.tipoPago = this.formasPago.find(f => f.label == this.paymentMethod.forma_pago)?.value ?? 1
            this.payment = JSON.parse(JSON.stringify(this.paymentMethod));
            this.cuotas = this.payment.cuotas
            this.$refs.modalEditarFormaPago.toggle()
        },
        asignarNumeroCuotas(){
            if ( this.cuotas.length < this.numCuotas ) this.pushCuotas( this.numCuotas - this.cuotas.length )
            if ( this.cuotas.length > this.numCuotas ) this.deleteCuotas(this.numCuotas)
            this.popover = false
        },
        pushCuotas(numCuotas){
            for (let i = 0; i < numCuotas; i++) {
                this.cuotas.push({...this.cuotaDefault})
            }
        },
        deleteCuotas(numCuotas){
            this.cuotas.splice(numCuotas, this.cuotas.length);
        },
        deleteCuotaId(id){
            try {
                this.cuotas = this.cuotas.filter(e => e.id != id)
                this.numCuotas -= 1
            } catch (error) {}
        },
        valorPorPorcentaje(index){
            const valor = parseInt(this.oportunidadesInformacion.valor)*(parseInt(this.cuotas[index].porcentaje)/100)
            this.cuotas[index].valor = Number(valor > 0 ? valor.toFixed(2) : 0)
        },
        porcentajePorValor(index){
            const valor = (100*(parseInt(this.cuotas[index].valor)))/parseInt(this.oportunidadesInformacion.valor)
            this.cuotas[index].porcentaje = Number(valor > 0 ? valor.toFixed(2) : 0)
        },
        async editFormaPago(){
            let payload = {
                tipo: this.tipoPago,
                fecha_pago: moment(this.payment.fecha_pago).format('YYYY-MM-DD'),
                observaciones: this.payment.justificacion,
                cuotas_tipo: 1
            }
            if (this.tipoPago === 4) {
                payload.cuotas = []
                this.cuotas.forEach(el => {
                    payload.cuotas.push({
                        fecha_pago: moment(el.fecha).format('YYYY-MM-DD'),
                        porcentaje: el.porcentaje+".0",
                        valor: el.valor+".0",
                    })
                });
            }
            await this.Action_set_opportunity_payment_method({'id': this.oportunidadesInformacion.id, 'payload': payload})
            await this.Action_get_opportunity_payment_method(this.$route.params.id);
            this.$refs.modalEditarFormaPago.toggle()
        },

        //Funciones para validaciones ----
        validateCampos(index, tipo){ //retorna si el campo esta vacio o null para aplicar los estilos de requerido*
            switch (tipo) {
                case 1:
                    if (this.cuotas[index].fecha == '' || this.cuotas[index].fecha == null) {
                        return true
                    }else return false
                case 2:
                    if (this.cuotas[index].porcentaje == '') return true
                case 3:
                    if (this.cuotas[index].valor == '' || this.cuotas[index].valor == null) return true
                default:
                    return false;
            }
        },
        validateFecha(index){ //retorna si la fecha [index] es menor que la anterior para aplicar los estilos
            if (index >0 && this.cuotas[index].fecha != null && this.cuotas[index].fecha != '') {
                try {
                    let fechaMin = moment()
                    fechaMin = moment(this.cuotas[index-1].fecha)
                    let fechaActual = moment(this.cuotas[index].fecha)
                    let comparacion = moment(fechaMin).isBefore(fechaActual)
                    return !comparacion
                } catch (error) {
                    return true
                }
            }else return false
        },
        validateCuotas(){ //retorna si todo lo concerniente a cuotas esta validado
            if (this.tipoPago === 4) {
                let camposValidados = true
                let fechasValidadas = true
                this.cuotas.forEach((el, index) => {
                    if (el.fecha == '' || el.fecha == null || el.porcentaje == '' || el.valor == '' || el.valor == null) {
                        camposValidados = false
                    }
                    if (this.validateFecha(index)) {
                        fechasValidadas = false
                    }
                });
                if (camposValidados  && fechasValidadas) {return true} else return false
            }else return true
        },
        async validateFinal(){
            const valid = await this.$refs.veeValidator.validate();
            if (this.validateCuotas() && valid) {
                if (this.tipoPago === 4 && this.radioButton == 1 && this.totalPorcentaje != 100) {
                    new Notification({
                        message:'El porcentaje no concuerda con el 100%',
                        type:'error',
                        dangerouslyUseHTMLString: false
                    })
                }else if(this.tipoPago === 4 && this.radioButton == 2 && this.totalValor != this.oportunidadesInformacion.valor){
                    new Notification({
                        message:'El valor no concuerda con el total',
                        type:'error',
                        dangerouslyUseHTMLString: false
                    })
                }else this.editFormaPago()
            }
        }
    }
}
</script>

<style lang="css" scoped>
    .popover{
        left: -162px;
        top: -172px;
        width: 200px;
        height: 160px;
    }
    .validate{
        border: 1px solid red;
    }
    .validate-fecha{
        border: 1px solid #ffc107;
    }
    .icon-validate{
        position: absolute;
        top: 0;
    }
    .hr{
        height: 2px;
        background: var(--color-general);
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
    input{
        outline: none;
    }
</style>
