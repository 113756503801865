<template>
    <section class="">
        <titulo-divisor titulo="Forma de pago" class="mr-0" />

        <!-- Forma Contado - Contraentrega  --------------------------------------------------- -->
        <div v-if="tipoFormaPago('Contraentrega') || tipoFormaPago('Contado')" class="row bg-f5 mx-0 py-3 br-4">
            <div class="col-3 col-lg-4">
                <p class="f-10">Valor</p>
                <div class="d-middle">
                    <i class="icon-cash-multiple" />
                    <p class="f-14 f-500">{{ paymentMethod.valor | currency('$ ', 2, { thousandsSeparator: '.' }) }}</p>
                </div>
            </div>
            <div class="col-3 col-lg-2">
                <p class="f-10">Forma de pago</p>
                <div class="d-middle">
                    <i class="icon-cash-multiple" />
                    <p class="f-14 f-500">{{ paymentMethod.forma_pago }}</p>
                </div>
            </div>
            <div class="col-3 col-lg-3">
                <p class="f-10">Fecha de pago</p>
                <div class="d-middle">
                    <i class="icon-calendar-today" />
                    <p class="f-14 f-500">{{ paymentMethod.fecha_pago | helper-fecha('DD MMMM YYYY') }}</p>
                </div>
            </div>
            <div class="col-3 my-auto">
                <button class="btn btn-general btn-crear f-12 mx-1" @click="cambiarFormaPago">
                    Cambiar
                </button>
            </div>
        </div>

        <!--  Forma Credito  --------------------------------------------------- -->
        <div v-if="tipoFormaPago('Credito')" class="row bg-f5 mx-0 py-3 br-4">
            <div class="col-2 col-lg-3">
                <p class="f-10">Cupo</p>
                <div class="d-middle">
                    <i class="icon-cash-multiple" />
                    <p class="f-14 f-500">
                        {{ paymentMethod.valor | currency('$ ', 2, { thousandsSeparator: '.' }) }} / 
                        {{ paymentMethod.credito | currency('$ ', 2, { thousandsSeparator: '.' }) }}
                    </p>
                </div>
            </div>
            <div class="col-2 col-lg-2">
                <p class="f-10">Forma de pago</p>
                <div class="d-middle">
                    <i class="icon-cash-multiple" />
                    <p class="f-14 f-500">{{ paymentMethod.forma_pago }}</p>
                </div>
            </div>
            <div class="col-2 col-lg-2">
                <p class="f-10">Fecha de pago</p>
                <div class="d-middle">
                    <i class="icon-calendar-today" />
                    <p class="f-14 f-500">{{ paymentMethod.fecha_pago | helper-fecha('DD MMMM YYYY') }}</p>
                </div>
            </div>
            <div class="col-2 col-lg-2">
                <p class="f-10">Días de credito</p>
                <div class="d-middle">
                    <i class="icon-calendar-today" />
                    <p class="f-14 f-500">90 días</p>
                </div>
            </div>
            <div class="col-3 my-auto">
                <button class="btn btn-general btn-crear f-12 mx-1" @click="cambiarFormaPago">
                    Cambiar
                </button>
            </div>
        </div>

        <!--  Forma Cuotas  --------------------------------------------------- -->
        <div v-if="tipoFormaPago('Cuotas')" class="mb-4">
            <div class="row bg-f5 mx-0 py-3 br-4">
                <div class="col-2 col-lg-4">
                    <p class="f-10">Valor</p>
                    <div class="d-middle">
                        <i class="icon-cash-multiple" />
                        <p class="f-14 f-500">{{ paymentMethod.valor | currency('$ ', 2, { thousandsSeparator: '.' }) }}</p>
                    </div>
                </div>
                <div class="col-2 col-lg-2">
                    <p class="f-10">Forma de pago</p>
                    <div class="d-middle">
                        <i class="icon-cash-multiple" />
                        <p class="f-14 f-500">{{ paymentMethod.forma_pago }}</p>
                    </div>
                </div>
                <div class="col-2 col-lg-3">
                    <p class="f-10">Próxima fecha de pago</p>
                    <div class="d-middle">
                        <i class="icon-calendar-today" />
                        <p class="f-14 f-500">{{ proximaFechaPago() }}</p>
                    </div>
                </div>
                <div class="col-3 my-auto">
                    <button class="btn btn-general btn-crear f-12 mx-1" @click="cambiarFormaPago">
                        Cambiar
                    </button>
                </div>
            </div>
            <div class="row mx-0 border mt-4 bg-general text-white py-2 w-50">
                <div class="col-3 my-auto">
                    <p class="f-600 f-14">Cuotas</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-600 f-14">Fecha de pago</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-600 f-14">Porcentaje</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-600 f-14">Valor</p>
                </div>
            </div>
            <div v-for="(cuota, index) in paymentMethod.cuotas" :key="index" class="row border-top mx-0 py-2 w-50 text-muted">
                <div class="col-3 my-auto">
                    <p class="f-14">Cuota {{ index + 1 }}</p>
                </div>
                <div class="col-3 my-auto">
                    <div class="d-middle">
                        <i class="icon-calendar-today" />
                        <p class="f-14">{{ cuota.fecha | helper-fecha('DD-MM-YYYY') }}</p>
                    </div>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-14">{{ cuota.porcentaje }}%</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="f-14">{{ cuota.valor | currency('$ ', 2, { thousandsSeparator: '.' }) }}</p>
                </div>
            </div>
        </div>

        <!-- Forma Contado - Contraentrega  --------------------------------------------------- -->
        <div v-else class="d-middle bg-f5 p-4 gap-5 my-4">
            <p class="f-500">Sin forma de pago</p>
            <div class="col-3 my-auto">
                <button class="btn btn-general btn-crear f-12 mx-5" @click="cambiarFormaPago">
                    Cambiar
                </button>
            </div>
        </div>

        <modal-cambiar-forma-pago ref="modalCambiarFormaPago" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment';
import ModalCambiarFormaPago from '../partials/modalCambiarFormaPago.vue'
export default {
    components: {
        ModalCambiarFormaPago,
    },
    computed: {
        ...mapGetters({
            paymentMethod: 'oportunidades/oportunidades/paymentMethod'
        }),
    },
    created () {
        this.Action_get_opportunity_payment_method(this.$route.params.id);
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_payment_method: 'oportunidades/oportunidades/Action_get_opportunity_payment_method'
        }),
        cambiarFormaPago(){
            this.$refs.modalCambiarFormaPago.toggle()
        },
        tipoFormaPago(tipo){
            return this.paymentMethod?.forma_pago == tipo ? true : false
        },
        proximaFechaPago(){
            const fechasPosteriores = this.paymentMethod.cuotas.filter(f => new Date(f.fecha) > new Date())
            return fechasPosteriores.length ? fechasPosteriores[0].fecha : '-'
        }
    },
}
</script>

<style lang="scss" scoped>

</style>