<template>
    <modal ref="modalAgregarResponsable" titulo="Añadir responsable" adicional="Añadir" @adicional="agregarResponsable">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-9 my-2">
                <p class="input-label-top">Responsable</p>
                <el-select v-model="idResponsable" placeholder="Seleccionar responsable" filterable size="small" class="w-100">
                    <el-option v-for="item in selectResponsables" :key="item.id" :disabled="item.disable" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data(){
        return {
            idResponsable: '',
        }
    },
    async created() {
        await this.Action_get_select_opportunity_responsibles(this.$route.params.id)
    },
    computed: {
        ...mapGetters({
            oportunidadesResponsables: 'oportunidades/oportunidades/oportunidadesResponsables',
            select_opportunity_responsibles: 'selects/selects/select_opportunity_responsibles'
        }),
        selectResponsables(){
            if(Object.values(this.select_opportunity_responsibles).length > 0){
                this.select_opportunity_responsibles.map(e => {
                    e.disable = this.oportunidadesResponsables.some(s => s.id === e.id)
                })
                return this.select_opportunity_responsibles
            }
        }
    },
    methods: {
        ...mapActions({
            Action_get_select_opportunity_responsibles: 'selects/selects/Action_get_select_opportunity_responsibles',
            Action_add_opportunity_responsibles: 'oportunidades/oportunidades/Action_add_opportunity_responsibles',
        }),
        toggle(){
            this.idResponsable = ''
            this.$refs.modalAgregarResponsable.toggle()
        },
        async agregarResponsable(){
            await this.Action_add_opportunity_responsibles({id: this.$route.params.id, payload: {id_responsable: this.idResponsable}})
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
