<template>
    <section class="section-contactos-cliente">
        <titulo-divisor titulo="Contactos del cliente" class="mr-0">
            <div class="row">
                <div class="col-auto my-auto px-1">
                    <el-tooltip content="Añadir contacto" placement="bottom-end" effect="light" visible-arrow>
                        <div class="wh-32 rounded-circle bg-5d d-middle-center cr-pointer" @click="agregarContacto">
                            <i class="icon-account-plus text-white f-18" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div class="row mx-0">
            <div v-for="cliente in oportunidadesClientes" :key="cliente.id" class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                <div class="card-cliente bg-f5 border br-4 p-2">
                    <i class="icon-close-circle f-19 cr-pointer" @click="deleteCliente(cliente.id)" />
                    <card-mini-user :nombre="cliente.nombre" :cargo="cliente.cargo" :img="cliente.imagen" tamanoImg="48" />
                </div>
            </div>
        </div>
        <modal-agregar-contacto ref="modalAgregarContacto" />
        <modal-eliminar-contacto ref="modalEliminarContacto" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import modalAgregarContacto from '../partials/modalAgregarContacto.vue'
import ModalEliminarContacto from '../partials/modalEliminarContacto.vue';
export default {
    components: {
        modalAgregarContacto,
        ModalEliminarContacto,
    },
    async created () {
        await this.Action_get_opportunity_customers(this.$route.params.id);
    },
    computed: {
        ...mapGetters({
            oportunidadesClientes: 'oportunidades/oportunidades/oportunidadesClientes',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_customers: 'oportunidades/oportunidades/Action_get_opportunity_customers',
            Action_delete_opportunity_customer: 'oportunidades/oportunidades/Action_delete_opportunity_customer',
        }),
        agregarContacto(){
            this.$refs.modalAgregarContacto.toggle()
        },
        deleteCliente(idDelete){
            this.$refs.modalEliminarContacto.toggle(idDelete)
        }
},
}
</script>

<style lang="scss" scoped>
    .section-contactos-cliente{
        .card-cliente{
            position: relative;
            border-left: 3px solid var(--color-general) !important;
            .div-star{
                position: absolute;
                top: -13px;
                left: -13px;
                background: #dbdbdb;
            }
            .icon-close-circle{
                position: absolute;
                top: -11px;
                right: -11px;
            }
        }
    }
</style>