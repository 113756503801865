var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditarOp",attrs:{"titulo":"Editar oportunidad","tamano":"modal-lg","adicional":"Guardar"},on:{"adicional":_vm.editarOp}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row justify-content-center m-3 f-15"},[_c('div',{staticClass:"col-6 mb-2"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre de la oportunidad")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Nombre Oportunidad"},model:{value:(_vm.edit.nombre),callback:function ($$v) {_vm.$set(_vm.edit, "nombre", $$v)},expression:"edit.nombre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 mb-2"},[_c('ValidationProvider',{attrs:{"name":"tipo de oportunidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Tipo de oportunidad")]),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccionar tipo","filterable":"","size":"small"},model:{value:(_vm.edit.id_oportunidad_tipo),callback:function ($$v) {_vm.$set(_vm.edit, "id_oportunidad_tipo", $$v)},expression:"edit.id_oportunidad_tipo"}},_vm._l((_vm.select_opportunity_type),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center m-3 f-15"},[_c('div',{staticClass:"col-4 mb-2"},[_c('ValidationProvider',{attrs:{"name":"fecha de identifiación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Fecha de identificación")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","size":"small","placeholder":"Fecha identificación","disabled":""},model:{value:(_vm.edit.fecha_identificacion),callback:function ($$v) {_vm.$set(_vm.edit, "fecha_identificacion", $$v)},expression:"edit.fecha_identificacion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-4 mb-2"},[_c('ValidationProvider',{attrs:{"name":"fecha de cierre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Fecha de cierre")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","size":"small","placeholder":"Fecha cierra","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd"},model:{value:(_vm.edit.fecha_cierre),callback:function ($$v) {_vm.$set(_vm.edit, "fecha_cierre", $$v)},expression:"edit.fecha_cierre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-4 mb-2"},[_c('ValidationProvider',{attrs:{"name":"probabilidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Probabilidad")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Valor %"},model:{value:(_vm.edit.probabilidad),callback:function ($$v) {_vm.$set(_vm.edit, "probabilidad", $$v)},expression:"edit.probabilidad"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 my-3"},[_c('ValidationProvider',{attrs:{"name":"descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Descripción")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","placeholder":"Descripción","autosize":{ minRows: 3},"size":"small"},model:{value:(_vm.edit.descripcion),callback:function ($$v) {_vm.$set(_vm.edit, "descripcion", $$v)},expression:"edit.descripcion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }