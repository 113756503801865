<template>
  <section class="section-responsables">
        <titulo-divisor titulo="Responsables" class="mr-0">
            <div class="row">
                <div class="col-auto my-auto px-1">
                    <el-tooltip content="Añadir responsable" placement="bottom-end" effect="light" visible-arrow>
                        <div class="wh-32 rounded-circle bg-5d d-middle-center cr-pointer" @click="agregarResponsable">
                            <i class="icon-account-plus text-white f-18" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div class="row mx-0">
            <div v-for="item in oportunidadesResponsables" :key="item.id" class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                <div class="card-responsable bg-f5 border br-4 p-2">
                    <el-tooltip content="Asignar como responsable principal" placement="bottom" effect="light" visible-arrow>
                        <div v-if="item.responsable_principal === 1" class="div-star rounded-circle cr-pointer bg-transparent">
                            <img src="/img/generales/PrincipalIcon.svg" style="height: 30px;"/>
                        </div>
                        <div v-else @click="addPrincipalResponsible(item.id)" class="div-star rounded-circle cr-pointer wh-22">
                            <i class="icon-star text-white" />
                        </div>
                    </el-tooltip>
                    <i class="icon-close-circle cr-pointer f-19" @click="deleteResponsible(item.id)" />
                    <card-mini-user :nombre="item.nombre" :cargo="item.cargo" :img="item.imagen" tamanoImg="48" />
                </div>
            </div>
        </div>
        <modal-agregar-responsable ref="modalAgregarResponsable" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import modalAgregarResponsable from '../partials/modalAgregarResponsable.vue'
export default {
    components: {
        modalAgregarResponsable,
    },
    computed: {
        ...mapGetters({
            oportunidadesResponsables: 'oportunidades/oportunidades/oportunidadesResponsables',
        }),
    },
    methods: {
        ...mapActions({
            Action_delete_opportunity_responsibles: 'oportunidades/oportunidades/Action_delete_opportunity_responsibles',
            Action_get_opportunity_responsibles: 'oportunidades/oportunidades/Action_get_opportunity_responsibles',
            Action_add_principal_opportunity_responsibles: 'oportunidades/oportunidades/Action_add_principal_opportunity_responsibles',
        }),
        agregarResponsable(){
            this.$refs.modalAgregarResponsable.toggle()
        },
        async addPrincipalResponsible(idResponsible){
            await this.Action_add_principal_opportunity_responsibles({id: this.$route.params.id, payload: {id_responsable: idResponsible}})
            await await this.Action_get_opportunity_responsibles(this.$route.params.id)
        },
        async deleteResponsible(idDelete){
            await this.Action_delete_opportunity_responsibles({id: this.$route.params.id, payload: {id_responsable: idDelete}})
            await await this.Action_get_opportunity_responsibles(this.$route.params.id)
        }
},
}
</script>

<style lang="scss" scoped>
    .section-responsables, .section-contactos-cliente{
        .card-responsable{
            position: relative;
            border-left: 3px solid var(--color-general) !important;
            .div-star{
                position: absolute;
                top: -13px;
                left: -13px;
                background: #dbdbdb;
            }
            .icon-close-circle{
                position: absolute;
                top: -11px;
                right: -11px;
            }
        }
    }
</style>