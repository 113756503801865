<template>
    <section class="oportunidades-ver-info scroll-none overflow-auto">
        <div class="section-top">
            <div class="row mt-4">
                <div class="col-12 position-relative">
                    <img :src="oportunidadesInformacion.img_banner | helper-storage" alt="" class="img-banner-op br-4">
                    <img :src="oportunidadesInformacion.img | helper-storage" alt="" class="img-logo-op wh-106 rounded-circle position-absolute">
                </div>
            </div>
            <div class="row my-3">
                <div class="blank" />
                <div class="col">
                    <div class="row">
                        <p class="col-12 f-16 f-700 mb-2">{{ oportunidadesInformacion.nombre_oportunidad }}</p>
                        <div class="col-12 col-lg-auto f-14 my-auto">
                            <p class="f-500">
                                Creación: 
                                <span class="f-300">{{ oportunidadesInformacion.creacion | helper-fecha( 'DD MMM YYYY' ) }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-auto f-14 my-auto">
                            <p class="f-500">
                                Identificación:
                                <span class="f-300">{{ oportunidadesInformacion.identificacion | helper-fecha( 'DD MMM YYYY' ) }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-auto f-14 my-auto">
                            <p class="f-500">
                                Cierre: 
                                <span class="f-300">{{ oportunidadesInformacion.cierre | helper-fecha( 'DD MMM YYYY' ) }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-auto f-14 ml-auto my-auto">
                            <button class="btn btn-editar btn-general f-12" @click="editarOp">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <div class="br-10 bg-f5 p-3">
                        <div class="row">
                            <p class="col-12 col-lg-8 f-16 f-700 my-auto">Nueva planta Colombia</p>
                            <p class="col-12 col-lg-4 f-500 f-14 my-auto text-left text-lg-right">
                                Tipo: 
                                <span class="f-300">{{ oportunidadesInformacion.tipo }}</span>
                            </p>
                            <p class="col-12 f-12 lh-17 mt-2">{{ oportunidadesInformacion.descripcion }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 my-auto">
                    <indicador-valores titulo="Probabilidad" :valor="oportunidadesInformacion.probabilidad" porcentaje class="mb-3" />
                    <indicador-valores titulo="Valor" :valor="oportunidadesInformacion.valor" estilo="indicador-mint" moneda  />
                </div>
            </div>
        </div>

        <!-- Comentarios -->
        <div class="section-comentarios">
            <card-comentarios v-if="oportunidadesInformacion.comentario_final"
            :userName="oportunidadesInformacion.comentario_final.usuario_nombre" 
            :userRol="oportunidadesInformacion.comentario_final.usuario_cargo"
            :userImg="oportunidadesInformacion.comentario_final.usuario_imagen"
            :comentario="oportunidadesInformacion.comentario_final.comentario"
            :estado="oportunidadesInformacion.estado_final"
            />
        </div>

        <!-- Equipos -->
        <equipos-informacion/>

        <!-- Ciclos -->
        <ciclos :dataCiclo="oportunidadesCiclos" />
        
        <!-- Responsables -->
        <responsables />
        
        <!-- Responsables -->
        <contacto-clientes/>

        <!-- Formas de pago -->
        <forma-pago />

        <!-- partials -->
        <modal-editar-op ref="modalEditarOp" />
    </section>
    

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ModalEditarOp from './partials/modalEditarOp.vue'
import EquiposInformacion from './components/equiposInformacion.vue'
import cardComentarios from '../../components/cardComentarios.vue'
import Ciclos from './components/ciclos.vue'
import Responsables from './components/responsables.vue'
import ContactoClientes from './components/contactoClientes.vue'
import FormaPago from './components/formaPago.vue'
export default {
    components:{
        ModalEditarOp,
        cardComentarios,
        EquiposInformacion,
        Ciclos,
        Responsables,
        ContactoClientes,
        FormaPago,
    },
    data(){
        return{
            idOpportunity: this.$route.params.id,
        }
    },
    async created() {
        await this.Action_get_opportunity_ciclos(this.idOpportunity)
        await this.Action_get_opportunity_responsibles(this.idOpportunity)
    },
    computed: {
        ...mapGetters({
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            oportunidadesCiclos: 'oportunidades/oportunidades/oportunidadesCiclos', //oportunbidadesCiclos.estado: 1:Vigente - 2:Cerrado - 0:Sin iniciar
        }),
    },
    methods:{
        ...mapActions({
            Action_get_opportunity_ciclos: 'oportunidades/oportunidades/Action_get_opportunity_ciclos',
            Action_get_opportunity_responsibles: 'oportunidades/oportunidades/Action_get_opportunity_responsibles',
        }),
        editarOp(){
            this.$refs.modalEditarOp.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.oportunidades-ver-info{
    height: calc(100vh - 232px);
    .section-top{
        .img-banner-op{
            height: 128px;
            width: 100%;
            object-fit: cover;
            box-shadow: 0px 3px 6px #00000029;
        }
        .img-logo-op{
            left: 35px;
            bottom: -51px;
            box-shadow: 0 1px 4px 1px #cecece;
        }
        .blank{
            width: 150px;
        }
    }
    .section-comentarios{
        .icon-window-close, .icon-star, .icon-message-reply-text {
            &::before{
              margin-left: 0px;  
            }
        }
    }
}
</style>