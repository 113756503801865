<template>
    <modal ref="modalEliminarContactoCliente" titulo="Eliminar contacto cliente" adicional="Eliminar" @adicional="deleteCliente">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-9 my-2">
                <p class="input-label-top">Justificación</p>
                <el-input
                    type="textarea"
                    :rows="4"
                    v-model="justification">
                </el-input>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    data(){
        return {
            idDelete: -1,
            justification: '',
        }
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_customers: 'oportunidades/oportunidades/Action_get_opportunity_customers',
            Action_delete_opportunity_customer: 'oportunidades/oportunidades/Action_delete_opportunity_customer',
        }),
        toggle(idDelete){
            this.idDelete = idDelete
            this.justification = ''
            this.$refs.modalEliminarContactoCliente.toggle()
        },
        async deleteCliente(){
            await this.Action_delete_opportunity_customer({id: this.$route.params.id, payload: {id_contacto: this.idDelete, justificacion: this.justification}})
            await this.Action_get_opportunity_customers(this.$route.params.id);
            this.toggle(-1)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
