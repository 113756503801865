<template>
    <div class="row my-4 bg-f5 mx-0 py-2 br-4">
        <div class="col-6 col-lg-3">
            <card-mini-user :nombre="userName" :cargo="userRol" :img="userImg" tamanoImg="48" />
        </div>
        <div class="col-6 col-lg-2 f-14">
            <p class="f-600">Estado</p>
            <div class="d-flex">
                <i :class="`${estado === 2 ? 'icon-window-close text-danger' : 'icon-star text-success'} f-20 `" />
                <p class="f-500 my-auto">
                    {{ estado === 2  ? 'Perdida' : 'Ganada' }}
                </p>
            </div>
        </div>
        <div class="col-12 col-lg-7 mt-2 mt-lg-0 f-14">
            <p class="f-600">Comentario</p>
            <div class="d-flex">
                <i class="icon-message-reply-text f-20" />
                <p class="my-auto"> {{ comentario }} </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        userName:{
            type: String
        },
        userRol:{
            type: String
        },
        userImg:{
            type: String
        },
        comentario:{
            type: String
        },
        estado:{
            default: false
        }
    },
    data(){
        return{
            //estadoOp: 0,
        }
    }

}
</script>

<style>

</style>